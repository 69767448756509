import * as React from "react"
import Layout from "../../src/components/layout";
import { Heading } from "theme-ui"

export default function Page404({ ...props }) {
  return <Layout>
    <Heading as="h1" variant="styles.h1">
      404
    </Heading>
    <section sx={{ my: 5, variant: `layout.content` }}>
      Damn, coś się spaprało
    </section>
  </Layout>
}


